<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login/tiptaplogo_white.png" alt="login" class="mx-auto">
            </div>

            <!-- lg:w-1/2 sm:w-full md:w-full  -->
            <div class="vx-col lg:w-1/2 sm:w-full md:w-full d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h1 class="mb-4">Tiptap Admin</h1>
                  <p>Cambio de contraseña</p>
                </div>

                <div>

                    <vs-input
                      type="password"
                      name="old_password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Contraseña Anterior"
                      v-model="old_password"
                      class="w-full mt-6" />

                    <vs-input
                      type="password"
                      name="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Password"
                      v-model="password"
                      class="w-full mt-6" />

                    <vs-input
                      type="password"
                      name="re_password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Repetir Password"
                      v-model="re_password"
                      class="w-full mt-6" />

                  <vs-button class="float-right mt-6 mb-6 ml-6" :disabled="!validateForm" @click="loginJWT">Cambiar</vs-button>
                  <vs-button class="float-right mt-6 mb-6 ml-6" type="border" @click="$router.push($store.state.AppActiveUser.status == 4 ? '/logout' : '/')">Cancelar</vs-button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

export default {
  data() {
    return {
      email: "",
      old_password: "",
      password: "",
      re_password: "",
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.password != '' && this.old_password != '' && this.re_password != '' && this.re_password == this.password
    }
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          time: 8000,
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'top-center',
        })

        return false
      }
      return true
    },
    loginJWT() {
      //if (!this.checkLogin()) return

      // Loading
      this.$vs.loading()

      let payload = {
        email: this.$store.state.AppActiveUser.email ? this.$store.state.AppActiveUser.email : this.$store.state.AppActiveUser.account.email,
        oldPassword: this.old_password,
        newPassword: this.password
      }

      this.$store.dispatch('userManagement/changePassword', payload)
        .then(response => {
            if (response.status) {
              this.$vs.notify({
                time: 8000,
                title: 'Cambio de contraseña',
                text: response.data ? response.data : "Realizado exitosamente",
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success',
                position: 'top-center',
              })

              if(this.$store.state.AppActiveUser.status == 4) {
                this.$store.dispatch('userManagement/activateUser', payload.email)
                  .then(response => {
                    if (!response.data.status)
                      throw new Error(response.data.msg)
                  })
              }
            }
        })
        .then(() => {
          this.$vs.loading.close()
          // this.$router.push({name : 'login'})
          this.$store.dispatch('auth/logout').catch(error => console.log(error))
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 8000,
            title: 'Error',
            text: error,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position: 'top-center',
          })
        })
    },
  },
  created() {

    if(!this.$store.state.AppActiveUser.status == 4 && (this.$store.state.AppActiveUser.account == null || !this.$store.state.AppActiveUser.email && !this.$store.state.AppActiveUser.account.email)){
        this.$router.push({name: 'login'})
    }

    // Register Module UserManagement Module
    if(!moduleUserManagement.isRegistered) {
      this.$store.registerModule('userManagement', moduleUserManagement)
      moduleUserManagement.isRegistered = true
    }
  }
}
</script>

<style lang="css" scoped>
  .vx-card {
    background-color: transparent;
  }
  .vx-col > img {
    max-width: 100%;
  }
  .layout--full-page .full-page-bg-color {
    background-color: inherit;
  }
  .vs-con-input-label.is-label-placeholder {
    margin-top: 30px !important;
  }
</style>
